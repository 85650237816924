<template>
  <div>
    <a-form layout="horizontal">
      <div class="w-100">
        <div class="row">
          <div class="col-md-3">
            <a-form-item>
              <template #label>
                <a-typography-title :level="5">Выберите филиал</a-typography-title>
              </template>
              <a-select
                :options="stores"
                v-model:value="selectedStore"
                @change="updateStore"
                :option-filter-prop="'label'"
                placeholder="Филиал"
                style="width: 250px"
                size="large"
                show-search
                allow-clear
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
    <div class="clearfix">
      <a-dropdown
        class="my-2"
        v-if="selectedRowKeys.length"
      >
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="createQr">
              Создать QR
            </a-menu-item>
            <a-menu-item key="1" @click="deleteTable" v-if="user.role === 'admin'">
              Удалить выбранные столы
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="secondary">
          Выберите действие
          <DownOutlined/>
        </a-button>
      </a-dropdown>
      <a-typography-text v-if="selectedRowKeys.length" secondary class="mx-3">
        (Выделено: {{ selectedRowKeys.length }} элементов)
      </a-typography-text>
      <a-button
        v-if="showSelectAllButton"
        @click="selectAll"
        type="link"
      >Выделить все ({{ total }})
      </a-button>
      <a-button
        v-if="selectedRowKeys.length"
        @click="clearSelection"
        type="text"
        class="text-red"
      >
        Отменить
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :row-selection="rowSelection"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
      row-key="id"
    >
      <template #title>
        <div class="pb-1 overflow-hidden w-100">
          <a-typography-title :level="3" class="pull-left">Столы</a-typography-title>
          <vb-create-table
            :halls="hallOptions"
            @reload="reload"
          />
        </div>
      </template>
      <template #name="{ record }">
        <div v-if="editNameId === record.id" class="row">
          <div class="col-md-10">
            <a-input
              @keydown.esc="editNameId = 0"
              ref="inputName"
              @pressEnter="updateColumn(record.id, 'name', record.name); editNameId = 0"
              v-model:value="record.name"
            ></a-input>
          </div>
          <div class="col-md-2 text-center row">
            <a-button
              @click="updateColumn(record.id, 'name', record.name); editNameId = 0" type="link" shape="round"
              class="px-2">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-10">
            <div @click="onEditNameId(record.id)" class="pull-left" :style="{ lineHeight: '30px' }">{{ record.name }}</div>
          </div>
          <div class="col-md-2 text-center row">
            <a-button @click="onEditNameId(record.id)" type="text" shape="round" class="px-2 pull-right">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #number="{ record }">
        {{ record.number }}
      </template>
      <template #hall_id="{ record }">
        <div v-if="editHallId === record.id" class="row">
          <div class="col-md-10">
            <a-select
              @keydown.esc="editHallId = 0"
              :options="hallOptions"
              v-model:value="record.hall_id"
              ref="inputHall"
              style="width: 100%"
              @change="updateColumn(record.id, 'hall_id', record.hall_id); editHallId = 0"
            ></a-select>
          </div>
          <div class="col-md-2 text-center row">
            <a-button
              @click="updateColumn(record.id, 'hall_id', record.hall_id); editHallId = 0" type="link" shape="round"
              class="px-2">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-10">
            <div @click="onEditHallId(record.id)" class="pull-left" :style="{ lineHeight: '30px' }">{{ record.hall?.name }}</div>
          </div>
          <div class="col-md-2 text-center row">
            <a-button @click="onEditHallId(record.id)" type="text" shape="round" class="px-2 pull-right">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #user="{ record }">
        {{ formattedPhone(record.user?.phone) }}
      </template>
      <template #status="{ record }">
        <a-tag
          v-if="record['status']"
          color="success"
          class="cursor-pointer"
          @click="updateColumn(record.id, 'status', 0)"
        >
          <template #icon>
            <CheckCircleOutlined />
          </template>
          Активный
        </a-tag>
        <a-tag
          v-else
          color="error"
          class="cursor-pointer"
          @click="updateColumn(record.id, 'status', 1)"
        >
          <template #icon>
            <MinusCircleOutlined />
          </template>
          Неактивный
        </a-tag>
      </template>
      <template #qr="{ record }">
        <a v-if="record['qr']" :href="record['qr']" class="cursor-pointer" target="_blank">
          <img :src="record['qr']" alt="" width="30">
        </a>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author?.username">
          ({{ record.author.username }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="showDeleteModal(record['id'])"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 d-flex justify-content-between">
      <p><b>Количество:</b> {{ total }}</p>
      <a-pagination
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      :title="`Номер стола:`"
      :visible="deskModal"
    >
      <template #footer>
        <a-button key="back" @click="() => {}">Закрыть</a-button>
      </template>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Продукт</th>
          <th>Количество</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in orderItems" :key="key">
            <td>
              <div class="order-image" :style="{ backgroundImage: `url(${item.item['imageUrl']})` }">
              </div>
              <span class="order-name">{{ item.item.name }}</span>
            </td>
            <td><span class="order-count">{{ item.count }}</span></td>
          </tr>
        </tbody>
      </table>
    </a-modal>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="hideDeleteModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить стол?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteDesk" type="primary" class="float-right">Да</a-button>
        <a-button @click="hideDeleteModal" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import {
  MinusCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  SaveOutlined,
  DownOutlined
} from '@ant-design/icons-vue';
import {computed, nextTick, onMounted, reactive, ref, toRefs, unref, watch} from 'vue';
import VbCreateTable from "@/views/store/menu/tables/create.vue";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import { message, notification } from 'ant-design-vue'
import apiClient from "@/services/axios";
import { useStore } from 'vuex'
import { mask } from "maska";

let deskModal = ref(false),
  stores = ref([]),
  selectedStore = ref('null'),
  orderItems = ref([]),
  deleteId = ref(0),
  deleteModal = ref(false),
  editNameId = ref(0),
  editHallId = ref(0),
  inputName = ref('inputName'),
  inputHall = ref('inputHall'),
  dataSource = ref([]),
  hallOptions = ref([]),
  loading = ref(false),
  selectedRowKeys = ref([]),
  showSelectAllButton = ref(false),
  total = ref(0);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  onSelectChange = changeableRowKeys => {
    selectedRowKeys.value = changeableRowKeys;
  },
  rowSelection = computed(() => {
    return {
      selectedRowKeys: unref(selectedRowKeys),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      selections: null,
    };
  }),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: 'left',
      slots: { customRender: 'name' },
    },
    {
      title: 'Номер стола',
      dataIndex: 'number',
      key: 'number',
      slots: { customRender: 'number' },
    },
    {
      title: 'Зал',
      dataIndex: 'hall_id',
      key: 'hall_id',
      slots: { customRender: 'hall_id' },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      slots: { customRender: 'status' },
    },
    {
      title: 'QR',
      dataIndex: 'qr',
      key: 'qr',
      slots: {customRender: 'qr'},
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      key: 'action',
      fixed: 'right',
      width: 50,
      slots: { customRender: 'action' },
    },
  ],
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  onEditNameId = (id) => {
    editNameId.value = id;
    nextTick(() => inputName.value?.focus());
  },
  onEditHallId = (id) => {
    editHallId.value = id;
    nextTick(() => inputHall.value?.focus());
  },
  createQr = () => {
    if (!confirm('Вы уверены что хотите создать QR-коды для выбранных столов?')) return false
    return apiClient.post('/desk/create-qr', {
      ids: selectedRowKeys.value
    }).then(() => {
      notification.success({
        message: 'Успешно',
      })
      // console.log(data)
    }).finally(() => {
      selectedRowKeys.value = []
      reload()
    })
  },
  deleteTable = () => {
    if (!confirm('Вы уверены что хотите удалить выбранные столы?')) return false
    return apiClient.post('/desk/delete-tables', {
      ids: selectedRowKeys.value
    }).then(() => {
      notification.success({
        message: 'Успешно',
        description: 'Столы удалены успешно',
      })
      // console.log(data)
    }).finally(() => {
      selectedRowKeys.value = []
      reload()
    })
  },
  clearSelection = () => {
    selectedRowKeys.value = []
  },
  selectAll = () => {
    const params = {...queryParams}
    return apiClient.get('/desk/ids', { params }).then(({data}) => {
      if (data['data']) {
        selectedRowKeys.value = data['data']
      }
    })
  },
  updateColumn = (id, column, value) => {
    let data = {};
    data[column] = value;
    apiClient.put(`/desk/update?desk=${id}`, data).then(response => {
      if (response) {
        message.success('Стол успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  showDeleteModal = (id) => {
    deleteId.value = id
    deleteModal.value = true
  },
  hideDeleteModal = () => {
    deleteId.value = 0
    deleteModal.value = false
  },
  deleteDesk = () => {
    return apiClient.delete(`desk/delete?desk=${deleteId.value}`).then(response => {
      if (response) {
        message.success('Стол успешно удален')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      hideDeleteModal()
      reload()
    });
  },
  updateStore = (store) => {
    router.replace({
      name: 'tables',
      params: { store }
    })
  },
  getData = async (store, params) => {
    loading.value = true
    if (store === 'null') {
      await apiClient.get('/desk/index?store=null', { params }).then(({ data }) => {
        store = data.data.stores.find(store => store.value !== 'null')?.value
        selectedStore.value = store
        router.replace({
          name: 'tables',
          params: { store }
        })
      })
    }
    return apiClient.get(`/desk/index?store=${store}`, { params }).then(({data}) => {
      loading.value = false
      dataSource.value = data.data.items
      hallOptions.value = data.data.halls
      stores.value = data.data.stores
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData(route.params.store, {
      ...queryParams,
    })
  };

onBeforeRouteUpdate((to, from, next) => {
  getData(to.params.store,{
    ...queryParams,
  })
  next()
})
onMounted(() => {
  getData(route.params.store, {
    ...queryParams,
  })
})

watch(selectedRowKeys, (value) => {
  showSelectAllButton.value = value.length === queryParams['per-page']
})

watch(queryParams, () => {
  router.push({
    name: 'tables',
    params: {
      store: route.params.store,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData(route.params.store, {
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>
<style>
.order-image {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}
.order-name {
  display: block;
  float: left;
  line-height: 50px;
}
.order-count {
  line-height: 50px;
}
.cursor-pointer {
  cursor: pointer;
}
.save-button {
  width: 32px;
  height: 32px;
}
</style>
